import {createRouter, createWebHistory} from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue';

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import('../views/NotificationsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/timetracking',
        name: 'TimeTracking',
        component: () => import('../views/TimeTrackingPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/timetracking/:id',
        name: 'TimeTrackingEntry',
        component: () => import('../views/TimeTrackingEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/absence',
        name: 'Absence',
        component: () => import('../views/AbsencePage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/illness',
        name: 'Illness',
        component: () => import('../views/IllnessPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/chat',
        name: 'Chat',
        component: () => import('../views/ChatPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/admin',
        name: 'AdministrationPage',
        component: () => import('../views/AdministrationPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: () => import('../views/SettingsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/administration',
        name: 'SettingsAdminPage',
        component: () => import('../views/SettingsAdminPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/administration/organization/:id',
        name: 'SettingsAdminOrgPage',
        component: () => import('../views/SettingsAdminOrgPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/administration/user/:id',
        name: 'SettingsAdminUserPage',
        component: () => import('../views/SettingsAdminUserPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/administration/organization/:id/newuser',
        name: 'SettingsAdminNewUserPage',
        component: () => import('../views/SettingsAdminNewUserPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/hrmngment',
        name: 'Personalverwaltung',
        component: () => import('../views/PersonellManagementPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/hrmngment/edit/:id',
        name: 'PersonalverwaltungEdit',
        component: () => import('../views/PersonellManagementEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/patientmngment',
        name: 'Patientenverwaltung',
        component: () => import('../views/PatientManagementPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/patientmngment/edit/:id',
        name: 'PatientenverwaltungEdit',
        component: () => import('../views/PatientManagementEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/recipes/',
        name: 'Rezepte',
        component: () => import('../views/RecipesPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/userdata/',
        name: 'Patientendaten',
        component: () => import('../views/UserdataPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/recipes/edit/:id',
        name: 'RezeptEdit',
        component: () => import('../views/RecipeEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/recipes/registration/:id',
        name: 'RezeptRegistration',
        component: () => import('../views/RecipeRegistrationPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/appointments/',
        name: 'Termine',
        component: () => import('../views/AppointmentsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/controlappointments/',
        name: 'Termineverwaltung',
        component: () => import('../views/AppointmentsControlPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/controlappointments/:id',
        name: 'Termine Absagen',
        component: () => import('../views/TherapistsAppointments.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workout_overview/',
        name: 'WorkoutOverview',
        component: () => import('../views/WorkoutOverviewPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workout/:id',
        name: 'Workout',
        component: () => import('../views/WorkoutPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workout/exercises/:id',
        name: 'Exercise',
        component: () => import('../views/ExercisesDoing.vue'), // Hier ist Router zu Übungen
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workout/edit/new_exercise_catalog',
        name: 'ExerciseCatalog',
        component: () => import('../views/WorkoutExerciseEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workout/edit/:id',
        name: 'WorkoutEdit',
        component: () => import('../views/WorkoutEditPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/documents/:id?',
        name: 'Dokumente',
        component: () => import('../views/DocumentsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/invoices/',
        name: 'Rechnungen',
        component: () => import('../views/InvoicesPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/development/',
        name: 'Entwicklung',
        component: () => import('../views/Development/DevelopmentPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/verordnungsservice/',
        name: 'Verordnungsservice',
        component: () => import('../views/Verordnungsservice/VerordnungsservicePage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/invoices/:invoice_id/',
        name: 'Rechnung',
        component: () => import('../views/InvoiceDetails.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginPage.vue'),
    },
    {
        path: '/set_password/:email',
        name: 'SetPassword',
        component: () => import('../views/SetPassword.vue'),
    },
    {
        path: '/user_list/',
        name: 'UserList',
        component: () => import('../views/UserListPage.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
